import { GridColDef } from "@mui/x-data-grid";

export const migrationForecastColumnDefs: GridColDef[] = [
    { field: 'year', headerName: 'Vuosi', width: 90 },
    {
        field: 'emigration',
        headerName: 'Maastamuutto',
        width: 150,
        editable: false,
      },
      {
        field: 'immigration',
        headerName: 'Maahanmuutto',
        width: 150,
        editable: false,
      },
      {
        field: 'births',
        headerName: 'Syntyvät',
        width: 150,
        editable: false,
      },
      {
        field: 'deaths',
        headerName: 'Kuolevat',
        width: 150,
        editable: false,
      },
      {
        field: 'interMunicipalMigrationIn',
        headerName: 'Kuntien välinen tulomuutto',
        width: 200,
        editable: false,
      },
      {
        field: 'interMunicipalMigrationOut',
        headerName: 'Kuntien välinen lähtömuutto',
        width: 200,
        editable: false,
      },  
]

export type MigrationForecastType = {
    "year": string,
    "emigration": number,
    "immigration": number,
    "interMunicipalMigrationIn": number,
    "interMunicipalMigrationOut": number,
}